<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Change Password</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formPassword" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <div v-if="data.password" class="text-center pt-1">
                <TextField
                  :value="oldPassword"
                  label="Old Password"
                  :rules="oldPasswordRules"
                  type="password"
                  @input="oldPassword = $event"
                ></TextField>
              </div>
              <div class="text-center pt-1">
                <TextField
                  :value="newPassword"
                  label="New Password"
                  :rules="newPasswordRules"
                  type="password"
                  @input="newPassword = $event"
                ></TextField>
              </div>
              <div class="text-center pt-1">
                <TextField
                  :value="confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  type="password"
                  @input="confirmPassword = $event"
                ></TextField>
              </div>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextField from "../../components/Form/TextField.vue";
export default {
  components: {
    TextField,
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
    confirmPasswordRules: [],
    newPasswordRules: [],
    oldPasswordRules: [],
    data: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.updateBreadcrumb()
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/check";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };

    //EOC
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/changePassword";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$router.push({ name: "PageAccountMyAccount" });
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  watch: {
    newPassword: function () {
      this.newPasswordRules = [];
    },
    oldPassword: function () {
      this.oldPasswordRules = [];
    },
    confirmPassword: function () {
      this.confirmPasswordRules = [];
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "PageAccountMyAccount" });
    },
    updateBreadcrumb() {
      this.breadcrumbs.push({
        text: "My Account",
        to: { name: "PageAccountMyAccount" },
        exact: true,
      });

      this.breadcrumbs.push({
        text: "Change Password",
        to: {
          name: "PageAccountChangePassword",
        },
        exact: true,
      });
    },

    submit() {
      if(this.data.password){
        this.oldPasswordRules = [(v) => !!v || "Old password is required"];
      }
      this.newPasswordRules = [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Min 8 characters",
      ];
      this.confirmPasswordRules = [
        (v) => !!v || "Password is required",
        this.passwordConfirmationRule,
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formPassword.validate()) {
          self.editApi.params = {
            password: self.newPassword,
            oldPassword: self.oldPassword,
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>